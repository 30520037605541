<template>
  <div>
    <el-form>
      <el-form-item label="Nombre de alianza">
        <el-input v-model="AlianzaObject.name" />
      </el-form-item>
      <el-form-item>
        <el-button
          class="fundacion-button"
          @click="saveAlianza">Guardar</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import AlianzasAPI from '../../API/comunidad/alianzas';

export default {
  data() {
    return {
      AlianzaObject: {
        name: null,
      },
    };
  },
  methods: {
    async saveAlianza() {
      const request = await AlianzasAPI.postAlianza(this.AlianzaObject);
      if (request) {
        this.$notify({
          type: 'success',
          message: 'Alianza agregada correctamente!',
        });
      }
    },
  },
};
</script>

<style>
.fundacion-button {
  background-color: rgb(157, 108, 229);
  color: #ffffff;
}
</style>
