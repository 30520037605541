<template>
  <div>
    <el-form label-position="top">
      <el-form-item label="Estados">
        <el-select
          @change="getMunicipios"
          filterable
          v-model="estado"
          clearable
          placeholder="Seleccionar">
        <el-option
          v-for="item in estados"
          :key="item.id"
          :label="item.name"
          :value="item.name"  />
      </el-select>
      </el-form-item>
      <el-form-item label="Municipio" prop="municipio">
        <el-select
          filterable
          v-model="DelegacionObject.municipio"
          clearable
          placeholder="Seleccionar">
        <el-option
          v-for="item in municipios"
          :key="item.id"
          :label="item.name"
          :value="item.id"  />
      </el-select>
      </el-form-item>
      <el-form-item label="Nombre de la delegación">
        <el-input v-model="DelegacionObject.name" />
      </el-form-item>
      <el-form-item>
        <el-button
          class="fundacion-button"
          @click="save">Guardar</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import DelegacionAPI from '../../API/comunidad/delegacion';
import { estadosAPI, municipiosAPI } from '../../utils/estados';

export default {
  data() {
    return {
      municipios: [],
      estados: [],
      estado: null,
      DelegacionObject: {
        name: null,
        municipio: null,
      },
    };
  },
  methods: {
    async getMunicipios() {
      this.municipios = [];
      this.DelegacionObject.municipio = null;
      const { estado } = this;
      const municipios = await municipiosAPI[0][estado];
      municipios.forEach((municipio) => {
        this.municipios.push({
          id: municipio,
          name: municipio,
        });
      });
    },
    async save() {
      try {
        const request = await DelegacionAPI.postdelegacion(this.DelegacionObject);
        if (request) {
          this.$notify({
            type: 'success',
            message: 'Delegación agregada correctamente!',
          });
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
  },
  async created() {
    estadosAPI.forEach((estado) => {
      this.estados.push({
        id: estado.clave,
        name: estado.nombre,
      });
    });
  },
};
</script>

  <style lang="scss">
  .fundacion-button {
    background-color: rgb(157, 108, 229);
    color: #ffffff;
  }
  .el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-row {
    width: 200px;
  }
}
  </style>
