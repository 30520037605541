<template>
    <div>
      <el-form
        :model="DataObject"
        label-position="top"
        :rules="rules"
        ref="DataObject"
      >
      <el-form-item label="Estados">
        <el-select
          @change="getMunicipios"
          filterable
          v-model="estado"
          clearable
          placeholder="Seleccionar">
        <el-option
          v-for="item in estados"
          :key="item.id"
          :label="item.name"
          :value="item.name"  />
      </el-select>
      </el-form-item>
      <el-form-item label="Municipio" prop="municipio">
        <el-select
          filterable
          v-model="DataObject.municipio"
          clearable
          placeholder="Seleccionar">
        <el-option
          v-for="item in municipios"
          :key="item.id"
          :label="item.name"
          :value="item.id"  />
      </el-select>
      </el-form-item>
      <el-form-item  label="Código postal" prop="codigoPostal">
          <el-input v-model="DataObject.codigoPostal" />
        </el-form-item>
        <el-form-item label="Nombre de la colonia" prop="name">
          <el-input v-model="DataObject.name" />
        </el-form-item>
        <el-form-item>
          <el-button
            class="fundacion-button"
            @click="submitForm('DataObject')">Guardar</el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>

<script>
import ColoniaAPI from '../../API/comunidad/colonias';
import { estadosAPI, municipiosAPI } from '../../utils/estados';

export default {
  data() {
    return {
      municipios: [],
      estados: [],
      estado: null,
      DataObject: {
        name: null,
        codigoPostal: null,
        municipio: null,
      },
      rules: {
        name: { required: true, message: 'Campo requerido', trigger: 'blur' },
        codigoPostal: [
          { required: true, message: 'Campo requerido', trigger: 'blur' },
          {
            min: 5, max: 5, message: 'La longitud debe ser igual a 5 números', trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    async getMunicipios() {
      this.municipios = [];
      this.DataObject.municipio = null;
      const { estado } = this;
      const municipios = await municipiosAPI[0][estado];
      municipios.forEach((municipio) => {
        this.municipios.push({
          id: municipio,
          name: municipio,
        });
      });
    },
    submitForm(formName) {
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    async save() {
      try {
        const request = await ColoniaAPI.postcolonia(this.DataObject);
        if (request) {
          this.$notify({
            type: 'success',
            message: 'Colonia agregada correctamente!',
          });
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
  },
  async created() {
    estadosAPI.forEach((estado) => {
      this.estados.push({
        id: estado.clave,
        name: estado.nombre,
      });
    });
  },
};
</script>

  <style lang="scss">
  .fundacion-button {
    background-color: rgb(157, 108, 229);
    color: #ffffff;
  }
  .el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-row {
    width: 200px;
  }
}
  </style>
