<template>
  <div class="container-comunidad">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      filename="HISTORIAL OFTALMOLOGÍA"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="table-comunidad-pdf">
          <h1 class="title">HISTORIAL DE PROYECTOS</h1>
          <el-table
            style="margin-top: 30px;"
            :data="projectInformation"
            stripe
          >
            <el-table-column property="id" label="ID"  />
            <el-table-column property="name" label="NOMBRE" />
            <el-table-column property="colonia.name" label="COLONIA" />
            <el-table-column property="status" label="ESTADO" />
            <el-table-column property="date" label="FECHA" />
          </el-table>
        </div>
      </section>
    </vue-html2pdf>
    <userInfo />
    <h1 class="title">DETECCIÓN TEMPRANA</h1>
    <div class="entidades-container">
      <span @click="showAlianzas = true" for="ALIANZAS">
        ALIANZAS
      </span>
      <span @click="showColonia = true" for="COLONIAS">
        COLONIAS
      </span>
      <span @click="showDelegacion = true" for="DELEGACIONES">
        DELEGACIONES
      </span>
    </div>
    <div class="cards-comunitaria">
      <CardComunidad name="jornadaClinica" title="Jornada clínica"/>
      <CardComunidad name="consultorioMovil" title="Consultorio móvil"/>
      <!-- <CardComunidad name="feriaDeLasalud" title="Ferias de la salud"/>
      <CardComunidad name="promotorasComunitarias" title="Promotoras comunitarias"/>
      <CardComunidad name="talleres" title="Talleres"/> -->
    </div>
    <div class="table-comunidad">
      <h1 class="title">HISTORIAL PROYECTOS</h1>
      <el-table
        style="margin-top: 30px;"
        :data="projectInformation"
        stripe
      >
        <el-table-column property="id" label="ID"  />
        <el-table-column property="name" label="NOMBRE" />
        <el-table-column property="colonia.name" label="COLONIA" />
        <el-table-column property="status" label="ESTADO" />
        <el-table-column property="date" label="FECHA" />
      </el-table>
      <el-button id="btn-fundacion" @click="generateReport()">Imprimir</el-button>
    </div>
    <el-dialog :visible.sync="showDelegacion" title="Agregar delegación">
      <Delegacion />
    </el-dialog>
    <el-dialog :visible.sync="showColonia" title="Agregar colonia">
      <Colonia />
    </el-dialog>
    <el-dialog :visible.sync="showAlianzas" title="Agregar alianza">
      <Alianzas />
    </el-dialog>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import CardComunidad from '../../components/CardComunidad';
import userInfo from '../../components/userInfo';
import Alianzas from './Alianzas';
import Delegacion from './Delegacion';
import Colonia from './Colonias';

export default {
  data() {
    return {
      projectInformation: [],
      showMunicipio: false,
      showDelegacion: false,
      showColonia: false,
      showAlianzas: false,
    };
  },
  components: {
    VueHtml2pdf,
    CardComunidad,
    userInfo,
    Alianzas,
    Colonia,
    Delegacion,
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async getProjectInformation() {
      try {
        const request = await this.axios.get('patient/comunidad/');
        if (request) {
          this.projectInformation = request.data;
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  async created() {
    await this.getProjectInformation();
  },
};
</script>
<style scoped>
.table-comunidad {
  margin-top: 80px;
  width: 100%;
  padding: 20px;
  margin-bottom: 350px;
}
.table-comunidad-pdf {
  width: 100%;
  padding: 20px;
  margin-bottom: 350px;
}
</style>
<style lang="scss" >
.container-comunidad{
  font-family: 'Poppins', sans-serif;
}
.title {
  color: #8F70C7;
  text-align: center;
}
.cards-comunitaria {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.entidades-container {
  display: flex;
  padding-left: 20px;
  flex-direction: row;
  gap: 15px;
  span {
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    background-color: #8F70C7;
    width: 150px;
  }
}
#btn-fundacion {
  float: right;
  background-color: rgba(111, 33, 199, 0.739);
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  border-radius: 8px;
  border: none;
}
</style>
