<template>
  <div class="container-tableComunidad">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      filename="HISTORIAL OFTALMOLOGÍA"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="container-tableComunidad">
          <h1 class="title">Historial de {{ nombreProyecto }}</h1>
          <el-table :data="informacionProyecto">
            <el-table-column property="id" label="ID" />
            <el-table-column property="date" label="Fecha" />
            <el-table-column property="alianza.name" label="Alianza" />
            <el-table-column property="colonia.name" label="Colonia" />
            <el-table-column property="status" label="Estado">
              <template slot-scope="scope">
                <el-tag
                  :type="statuses[scope.row.status]"
                >
                  {{ scope.row.status }}
                </el-tag>
            </template>
            </el-table-column>
          </el-table>
        </div>
      </section>
    </vue-html2pdf>
    <div class="opciones-comunidad-modal">
      <el-button
        size="medium"
        icon="el-icon-plus"
        @click="goTo(tipoProyecto)"
      >
        Nuevo proyecto
      </el-button>
      <el-button @click="generateReport">Imprimir</el-button>
    </div>
    <el-table
      :data="informacionProyecto"
      @row-click="clickCell"
      stripe
    >
      <el-table-column property="id" label="ID" width="50" />
      <el-table-column property="date" label="Fecha" width="120" />
      <el-table-column property="alianza.name" label="Alianza" width="120" />
      <el-table-column property="colonia.name" label="Colonia" width="120" />
      <el-table-column property="status" label="Status" width="120">
        <template slot-scope="scope">
          <el-tag
            :type="statuses[scope.row.status]"
          >
            {{ scope.row.status }}
          </el-tag>
      </template>
      </el-table-column>
      <el-table-column
      label="Opciones">
      <template slot-scope="scope">
        <el-button
          v-if="scope.row.status === 'ACTIVO'"
          size="mini"
          @click="handleCapturar(scope.row.id)">Registrar pacientes</el-button>
        <el-button
          size="mini"
          @click="handleInformation(scope.row.id)">Detalles</el-button>
      </template>
    </el-table-column>
    </el-table>
    <el-dialog
      title="Detalles del proyecto"
      :visible.sync="proyectForm"
      append-to-body
    >
      <el-form>
        <el-form-item label="ID">
          <el-input v-model="proyecto.id" disabled />
        </el-form-item>
        <el-form-item label="Alianza">
          <el-input v-model="proyecto.alianza.name" disabled />
        </el-form-item>
        <el-form-item label="Colonia">
          <el-input v-model="proyecto.colonia.name" disabled />
        </el-form-item>
        <el-form-item label="Estado">
          <br>
          <el-select v-model="proyecto.status" placeholder="Seleccione">
            <el-option
              v-for="item in Object.keys(statuses)"
              :key="item"
              :label="item"
              :value="item"
            />
            </el-select>
        </el-form-item>
        <el-form-item label="Hora" prop="hour">
          <br>
          <el-time-select
            v-model="proyecto.hour"
            :picker-options="{
              start: '08:30',
              step: '00:15',
              end: '18:30'
            }"
            placeholder="Seleccionar una hora"
          />
        </el-form-item>
        <el-form-item label="Fecha" >
          <el-input v-model="proyecto.date" disabled />
        </el-form-item>
        <datepicker
          v-if="selectedDate !== null"
          v-model="selectedDate"
          :value="selectedDate"
          :language="languages['es']"
          :inline="true"
          :use-utc="false"
        />
        <!-- Eliminar -->
        <div style="display: flex; justify-content: end; margin-top: 50px;">
          <el-button type="danger" @click="openDeleteDialog">
          Eliminar proyecto
        </el-button>
          <el-button
            class="fundacion-button"
            size="medium"
            @click="submitForm('FormProject')"
          >
            Cerrar
          </el-button>
          <el-button
            class="fundacion-button"
            size="medium"
            @click="patchProject()"
          >
            Guardar
          </el-button>
        </div>
      </el-form>
      <el-dialog
        title="¿Está seguro de eliminar el proyecto?"
        :visible.sync="deleteDialog"
        width="30%"
        append-to-body
      >
        <span>Esta acción no se puede deshacer</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteDialog = false">Cancelar</el-button>
          <el-button type="danger" @click="deleteProject(proyecto.id)">
            Eliminar
          </el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script lang="js">
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import VueHtml2pdf from 'vue-html2pdf';
import router from '../router';
import comunidadProyectoApi from '../API/comunidad/proyectos';

const moment = require('moment');

export default {
  props: {
    name: {
      type: String,
    },
    projectInformation: {
      type: Array,
    },
  },
  data() {
    return {
      deleteDialog: false,
      informacionProyecto: [],
      languages: lang,
      proyectForm: false,
      nombreProyecto: null,
      formLabelWidth: '120px',
      selectedDate: null,
      idxProjectSelected: null,
      proyecto: {
        id: null,
        date: null,
        hour: null,
        alianza: {
          name: null,
        },
        colonia: {
          name: null,
        },
        status: null,
      },
      statuses: {
        ACTIVO: 'success',
        CANCELADO: 'danger',
        CONCLUIDO: 'warning',
        POSPUESTO: 'primary',
      },
    };
  },
  components: {
    VueHtml2pdf,
    Datepicker,
  },
  methods: {
    openDeleteDialog() {
      this.deleteDialog = true;
    },
    async deleteProject() {
      try {
        const request = await comunidadProyectoApi.deleteProyecto(
          this.proyecto.id,
        );
        if (request) {
          this.$notify({
            title: 'Proyecto eliminado',
            message: 'El proyecto se ha eliminado correctamente',
            type: 'success',
          });
          window.location.reload();
        }
      } catch (error) {
        this.$notify({
          title: 'Error al eliminar el proyecto',
          message: 'No se ha podido eliminar el proyecto',
          type: 'error',
        });
        // eslint-disable-next-line no-console
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    clickCell(row) {
      this.proyecto = {
        id: row.id,
        date: row.date,
        hour: row.hour,
        alianza: {
          name: row.alianza.name,
        },
        colonia: {
          name: row.colonia.name,
        },
        status: row.status,
      };
      this.proyectForm = true;
      this.selectedDate = moment(row.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
      this.idxProjectSelected = this.projectInformation.findIndex(
        (project) => project.id === row.id,
      );
    },
    goTo() {
      router.push({ path: `/${this.name}` });
    },
    handleCapturar(id) {
      const registerName = `${this.name}Registro`;
      setTimeout(() => {
        router.push({ name: `${registerName}`, params: { proyectoId: id } });
      }, 200);
    },
    handleInformation(id) {
      router.push({ path: `/pacientes/${id}` });
    },
    async patchProject() {
      const request = await comunidadProyectoApi.patchProyecto(this.proyecto);
      if (request) {
        const newProjects = [
          ...this.informacionProyecto.slice(0, this.idxProjectSelected),
          this.proyecto,
          ...this.informacionProyecto.slice(this.idxProjectSelected + 1),
        ];
        this.informacionProyecto = newProjects;
        this.$notify({
          message: 'Actualizado correctamente!',
          type: 'success',
        });
        this.proyectForm = false;
      } else {
        this.$notify({
          message: 'Error al actualizar el proyecto',
          type: 'error',
        });
      }
    },
    async changedMonth(args) {
      const FORMAT = 'YYYY-MM-DD';
      let date = null;
      if (args.timestamp) {
        date = moment(new Date(args.timestamp)).format(FORMAT);
      } else {
        date = moment(args).format(FORMAT);
      }
      // await this.hightlights(date);
    },
  },
  created() {
    const nombres = {
      jornadaClinica: 'jornada clínica',
      consultorioMovil: 'consultorio móvil',
    };
    this.nombreProyecto = nombres[this.name];
    this.informacionProyecto = this.projectInformation;
  },
  watch: {
    selectedDate(val) {
      if (val) {
        this.selectedDate = moment(val).format();
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.proyecto.date = date;
      }
    },
  },
};
</script>

<style lang="scss">
.fundacion-button {
  background-color: rgb(157, 108, 229);
  color: #ffffff;
}
.opciones-comunidad-modal {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}
.container-tableComunidad {
  display: flex;
  flex-direction: column;
  .el-button {
    justify-content: center;
    align-self: end;
    background-color: #8F70C7;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-row {
    width: 200px;
  }
}
.container-crearProyecto {
  padding: 20px;
}
.el-form--label-top .el-form-item__label {
  padding: 0px;
}/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(157, 108, 229);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(123, 59, 218, 0.855);
  border-radius: 12px;
}
.vdp-datepicker__calendar {
  padding: 5px;
}
.vdp-datepicker__calendar .cell.selected {
  background: #6a41bdd7 !important;
  color: #fff;
}
.vdp-datepicker__calendar .cell.highlighted {
    background: #c4a7ff !important;
    color: #fff;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled):hover {
  background: rgba(104, 85, 247, 0.25) !important;
  color: #fff;
  border: none;
}
.vdp-datepicker__calendar {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 10px 2px rrgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 10px 2px rrgba(0, 0, 0, 0.19);
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: none !important;
}
.vdp-datepicker__calendar .cell.blank:hover {
  background: #ffffff !important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border-radius: 50%;
}
.vdp-datepicker__calendar .cell.selected:hover {
  background: #006eff;
}
.vdp-datepicker__calendar .cell:hover {
  background: #006eff;
}
</style>
