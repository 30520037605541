<template>
  <div class="cardComunidad">
    <div class="container-card-home" @click="selectedCard()">
      <div class="card-home">
        <label>{{ title }}</label>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogTableVisible"
      @close="clearData()"
    >
      <TableComunidad
        :projectInformation="projectInformation"
        :name="name"
      />
    </el-dialog>
  </div>
</template>

<script>
import TableComunidad from './TableComunidad';
import comunidadProyectoApi from '../API/comunidad/proyectos';

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'especialidad',
    },
  },
  data() {
    return {
      cardSelected: null,
      cards: [],
      projectInformation: [],
      cardIdRoute: null,
      dialogTableVisible: false,
    };
  },
  components: {
    TableComunidad,
  },
  methods: {
    async selectedCard() {
      const nameProject = this.name;
      const request = await comunidadProyectoApi.getcomunidadProyecto(nameProject);
      if (request) {
        this.projectInformation = request;
        this.dialogTableVisible = true;
      }
    },
    clearData() {
      this.projectInformation = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.container-card-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.card-home {
  background-color: #8F70C7;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 12px;
  width: 200px;
  height: 168px;
  padding: 25px;
  color: #fff;
  .icon-circle {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: #fff;
  }
  label {
    text-align: center;
    padding: 0px 5px;
    width: 175px;
    white-space: wrap;
    overflow: hidden;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
